export const isDevMode = () => {
    if (window.location.search.includes('?dev')) {
        localStorage.setItem('dev', 'true');
    }
    if (window.location.search.includes('?nodev')) {
        localStorage.setItem('dev', 'false');
    }

    return localStorage.getItem('dev') === 'true';
}

export const copyJson = (json) => navigator.clipboard.writeText(JSON.stringify(json, null, 2));
export const pasteJson = async () => JSON.parse(await navigator.clipboard.readText());

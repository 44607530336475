// Chakra Imports
import {Flex, IconButton, Menu, useColorMode, useColorModeValue} from '@chakra-ui/react';
// Custom Components
import {SidebarResponsive} from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React from 'react';
import routes from 'routes.js';
import {SunIcon} from "@chakra-ui/icons";
import {SignedIn, UserButton} from "@clerk/clerk-react";

export default function HeaderLinks(props) {
	const { secondary } = props;
	// Chakra Color Mode
	let menuBg = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const { toggleColorMode } = useColorMode()
	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			borderRadius="30px"
			boxShadow={shadow}>
			<SidebarResponsive routes={routes} />

			<IconButton
				aria-label="Toggle Color Mode"
				icon={<SunIcon />}
				onClick={toggleColorMode}
				size="sm"
				variant="ghost"
			/>

			<Menu placement={'left'}>
				<SignedIn>
					<UserButton/>
				</SignedIn>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};

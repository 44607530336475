import React, {useEffect, useState} from 'react';
import {Text, Textarea} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";

const InfoStepComponent = ({id, v, onChange, lite}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    return (
        <>
            <Text display={lite ? 'none' : 'block'}>Informacja:</Text>
            <Textarea
                defaultValue={value}
                // ref={ref => ref && ref.focus()}
                onBlur={(e) => setValue(e.target.value)}
                onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                id={id}
            />
        </>
    );
}

export default InfoStepComponent;
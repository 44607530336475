import React, {useEffect, useState} from 'react';
import {TagsInput} from "react-tag-input-component";
import {Checkbox, Select, Text} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";
import ListInputField from "../../../../../components/fields/ListInputField";
import TextOrFileField from "../../../../../components/fields/TextOrFileField";

const RiddleStepComponent = ({id, v, onChange, config, lite}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    const onChangeAnyAnswer = (e) => {
        if (e.target.checked) {
            setValue({...value, aa: true});
        } else {
            const { aa, ...rest } = value;
            setValue(rest);
        }
    }

    const onChangeSkipping = (v) => {
        if (!!v) {
            setValue({...value, s: v});
        } else {
            const { s, ...rest } = value;
            setValue(rest);
        }
    }

    const onChangeSkippingBlocked = (e) => {
        if (e.target.checked) {
            setValue({...value, sb: true});
        } else {
            const { sb, ...rest } = value;
            setValue(rest);
        }
    }

    return (
        <>
            <Text display={lite ? 'none' : 'block'} opacity={!!value.aa ? 0.5 : 1}>Poprawne odpowiedzi:</Text>
            <TagsInput
                value={[...new Set(value.a)]}
                onChange={(tags) => setValue({...value, a: tags})}
                inputProps={{maxLength: 15}}
                disabled={!!value.aa}
                opacity={!!value.aa ? 0.5 : 1}
                name={id + 'a'}
            />
            <Checkbox display={lite ? 'none' : 'block'} isChecked={!!value.aa} onChange={onChangeAnyAnswer} id={id + 'aa'}>
                Każda odpowiedź jest poprawna
            </Checkbox>

            <Text display={lite ? 'none' : 'block'} mt={4}>Podpowiedzi:</Text>
            <ListInputField id={id + 'h'} items={value.h} onChange={(h) => setValue({...value, h})} locked={lite} />

            <Text display={lite ? 'none' : 'block'} mt={4}>Gdy podano złą odpowiedź:</Text>
            <ListInputField id={id + 'wa'} items={value.wa || []} allowFiles={false} onChange={(wa) => setValue({...value, wa})} locked={lite} />
            {(config?.wrongs?.default?.length || 0) > 0 ? (
                    <Select minW={300} maxW={800} w={'100%'} display={(lite || !config?.wrongs?.extra?.length) ? 'none' : 'block'} id={id + 'eid'} value={value.eid || ''}
                            onChange={(e) => setValue({...value, eid: e.target.value || undefined})} mt={2}>
                        <option value="">Domyślne</option>
                        {config?.wrongs?.extra?.map(e => (
                            <option key={e.id} value={e.id}>Pakiet {e.name}</option>
                        ))}
                    </Select>
                ) :
                ''}

            <Text display={lite ? 'none' : 'block'} mt={4}>Podczas pomijania:</Text>
            <TextOrFileField
                id={id + 's'}
                initialValue={value.s}
                onChange={onChangeSkipping}
            />
            <Checkbox display={lite ? 'none' : 'block'} isChecked={!!value.sb} onChange={onChangeSkippingBlocked}  id={id + 'sb'}>
                Pomijanie jest zablokowane
            </Checkbox>
        </>
    );
}

export default RiddleStepComponent;
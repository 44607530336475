import React, {useEffect, useState} from 'react';
import {Box, Flex, Heading, IconButton, theme} from "@chakra-ui/react";
import {MdOutlineHelpOutline} from "react-icons/md";
import {useIsMount} from "./Scenario";
import {components} from "../variables/vars";

const stringToColor = (str) => {
    const colors = Object.keys(theme.colors).filter(color => typeof theme.colors[color] === 'object' && theme.colors[color][500]);

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 10) - hash);
    }

    // Use modulo to get a color index
    const colorIndex = Math.abs(hash) % colors.length;

    return theme.colors[colors[colorIndex]][500];
}

const StepLiteComponent = ({i, item, onChange, config}) => {
    const isMount = useIsMount();
    const [step, setStep] = useState(item);

    useEffect(() => {
        if (isMount) return;
        onChange(step);
    }, [step]);

    const StepComponent = components[item.t]?.component || (() => <></>);
    const IconComponent = components[item.t]?.icon || (() => <MdOutlineHelpOutline></MdOutlineHelpOutline>);
    const conditionalColor = item.c ? stringToColor(JSON.stringify(item.c)) : '';

    const isLiteSupported = components[item.t]?.lite || false;

    return (
        <Box display={isLiteSupported ? 'block' : 'none'}>
            <Flex item={item.id}>
                <Heading color={conditionalColor}
                         p={3}  fontWeight="thin" opacity={0.8} size={'xs'}>
                    #{i}
                </Heading>
                <IconButton pl={0} mr={2} ml={-2}
                            color={conditionalColor}
                            variant={'ghost'}
                            aria-label={item.name}
                            opacity={0.7}
                            icon={<IconComponent size={16} />}
                />

                <Flex w={'100%'}>
                    <Box pl={0} py={2} w={'100%'}>
                        <StepComponent id={step.id} v={step.v} onChange={(v) => setStep({ ...step, v })} config={config} lite={true} />
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
};

export default StepLiteComponent;
import React, {useEffect, useState} from 'react';
import {Checkbox, Textarea} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";

const MessageStepComponent = ({id, v, onChange, config, lite}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(typeof v === 'string' ? {t: v, nd: false} : ((v.v ?? false) ? {t: v.v, nd: false} : v));

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    return (
        <>
            {!lite ? 'Wiadomość:' : ''}
            <Textarea
                defaultValue={value.t}
                onBlur={(e) => setValue({...value, t: e.target.value})}
                onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                name={id}
            />

            <Checkbox
                isChecked={!!value.nd}
                onChange={e => setValue({...value, nd: !!e.target.checked})}
                id={id + 'nd'}
                display={!lite && config?.standard?.shouldCalculateDelays ? 'block' : 'none'}
            >
                Nie opóźniaj następnej wiadomości
            </Checkbox>
        </>
    );
}

export default MessageStepComponent;
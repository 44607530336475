import React, {useEffect, useState} from 'react';
import {Checkbox, Input, Text} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";
import {TagsInput} from "react-tag-input-component";
import ListInputField from "../../../../../components/fields/ListInputField";

const InputStepComponent = ({id, v, onChange, lite}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(typeof v === 'string' ? {v: v, a: []} : v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    const onChangeSkipping = (e) => {
        let skipping = e.target.value;
        if (!!skipping) {
            setValue({...value, s: skipping});
        } else {
            const { s, ...rest } = value;
            setValue(rest);
        }
    }

    return (
        <>
            <Text display={lite ? 'none' : 'block'}>
                Zapisz jako:
            </Text>
            <Input
                defaultValue={value.v}
                textTransform={'lowercase'}
                onBlur={(e) => setValue({...value, v: e.target.value.toLowerCase()})}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                display={lite ? 'none' : 'block'}
                id={id}
            />

            <Text display={lite ? 'none' : 'block'}>Dozwolone odpowiedzi:</Text>
            <TagsInput
                value={[...new Set(value.a || [])]}
                onChange={(tags) => setValue({...value, a: tags})}
                inputProps={{maxLength: 15}}
                name={id + 'a'}
            />

            <Text display={lite ? 'none' : 'block'} mt={6}>Podpowiedzi:</Text>
            <ListInputField id={id + 'h'} items={value.h || []} onChange={(h) => setValue({...value, h})} locked={lite} />

            <Text display={lite ? 'none' : 'block'} mt={4}>Gdy gracz próbuje pominąć wybór:</Text>
            <Input
                defaultValue={value.s || ''}
                onChange={onChangeSkipping}
                id={id + 's'}
            />
            <Checkbox isChecked={true} disabled={true} display={lite ? 'none' : 'block'}>
                Pomijanie jest zablokowane
            </Checkbox>
        </>
    );
}

export default InputStepComponent;
import React, { useEffect, useState, useRef } from "react";
import { Flex, IconButton, Input, InputGroup, InputRightElement, Tooltip } from "@chakra-ui/react";
import { MdOutlineImage, MdOutlineTextFormat } from "react-icons/md";
import toast from "react-hot-toast";
import { useIsMount } from "../../views/admin/scenario/components/Scenario";
import { isDevMode } from "../../contexts/DevContext";
import {useAuth} from "../../contexts/AuthProvider";
import {filesApi} from "../../api";

const TextOrFileField = ({ id, initialValue, placeholder, onChange, onBlur, onEnter, clearOnBlur, allowFiles, ...rest }) => {
    const isMount = useIsMount();
    const [mode, setMode] = useState((initialValue || '').startsWith('file://') ? 'file' : 'text');
    const [value, setValue] = useState((initialValue || ''));
    const [valueChanged, setValueChanged] = useState(false);
    const [uploading, setUploading] = useState(false);
    const fileRef = useRef();
    const {awaitAuthentication} = useAuth();

    const isFileMode = () => mode === 'file';

    onChange = onChange || (() => {});
    onBlur = onBlur || (() => {});
    onEnter = onEnter || (() => {});

    const encodeUri = ({ token, filename, type, timestamp }) =>
        `file://${token}?n=${encodeURIComponent(filename)}&t=${encodeURIComponent(type)}&ts=${encodeURIComponent(timestamp)}`;

    const decodeUri = (uri) => {
        const [token, params] = uri.replace('file://', '').split('?');
        const searchParams = new URLSearchParams(params);
        return {
            token,
            filename: decodeURIComponent(searchParams.get('n') || ''),
            type: decodeURIComponent(searchParams.get('t') || ''),
            timestamp: decodeURIComponent(searchParams.get('ts') || ''),
        };
    };

    const handleUpload = async () => {
        const file = fileRef.current.files[0];
        const formData = new FormData();
        formData.append('file', file);

        setUploading(true);

        try {
            const { token } = await filesApi({awaitAuthentication}).uploadFile(formData);
            const uri = encodeUri({
                token,
                filename: file.name,
                type: file.type,
                timestamp: new Date().toISOString(),
            });

            setValue(uri);
            onChange(uri);
            setValueChanged(false);
            onBlur && onBlur(uri);
            if (clearOnBlur) setValue('');

            toast.success('Pomyślnie wgrano plik');
        } catch (error) {
            console.error(error);
            toast.error('Nie udało się wgrać pliku');
        }

        setUploading(false);
        fileRef.current.value = '';
    };

    const handleInputChange = (e) => {
        setValue(e.target.value);
        setValueChanged(true);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onEnter(e.target.value);
            if (clearOnBlur) setValue('');
        }
    };

    const handleBlur = () => {
        if (valueChanged) {
            onChange(value);
            setValueChanged(false);
        }
        onBlur(value);
        if (clearOnBlur) setValue('');
    };

    const toggleType = () => {
        setMode(prevType => prevType === 'file' ? 'text' : 'file');
        if (!isFileMode()) fileRef.current.click();
    };

    useEffect(() => {
        if (!isMount) setValue('');
    }, [mode]);

    return (
        <Flex minW={300} maxW={800} w={'100%'} {...rest}>
            <InputGroup size='md'>
                <Input
                    name={id}
                    value={isFileMode() ? ('<< ' + (decodeUri(value)?.filename || '...') + ' >>') : value}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    bg={'white'}
                    readOnly={isFileMode()}
                    fontStyle={isFileMode() ? 'italic' : 'normal'}
                />
                {isDevMode() && (
                    <InputRightElement width='4.5rem' visibility={allowFiles ? 'visible' : 'hidden'}>
                        <Tooltip label={isFileMode() ? 'Zamień na tekst' : 'Zamień na załącznik'}>
                            <IconButton
                                zIndex={999}
                                h='1.75rem'
                                mr={-5}
                                borderRadius={3}
                                size='md'
                                icon={isFileMode() ? <MdOutlineTextFormat/> : <MdOutlineImage/>}
                                onClick={toggleType}
                                isLoading={uploading}
                                opacity={0.5}
                            />
                        </Tooltip>
                    </InputRightElement>
                )}
            </InputGroup>
            <Input
                id={`${id}f`}
                type="file"
                ref={fileRef}
                style={{display: 'none'}}
                onChange={handleUpload}
            />
        </Flex>
    );
};

export default TextOrFileField;

import React, {useEffect, useState} from 'react';
import {FormLabel, Switch, Text} from "@chakra-ui/react";
import {useIsMount} from "../Config";
import {TagsInput} from "react-tag-input-component";
import ListInputField from "../../../../../components/fields/ListInputField";

const StandardConfigComponent = ({initialConfig, onChange}) => {
    const isMount = useIsMount();
    const [config, setConfig] = useState(initialConfig);

    useEffect(() => {
        if (isMount) return;
        onChange(config);
    }, [config]);

    return (
        <>
            <FormLabel htmlFor='delays' mb={0}>
                Obliczanie opóźnienień
            </FormLabel>
            <Switch
                id='delays'
                size='lg'
                isChecked={config.shouldCalculateDelays}
                onChange={(e) => setConfig({...config, shouldCalculateDelays: Boolean(e.target.checked)})}
            />

            <Text mt={4}>Komenda podpowiedzi do zagadki</Text>
            <TagsInput
                value={[...new Set(config?.commands?.hinting || [])]}
                onChange={(tags) => setConfig({...config, commands: {...config?.commands || {}, hinting: tags}})}
                inputProps={{maxLength: 15}}
            />

            <Text mt={4}>Komenda pominięcia zagadki</Text>
            <TagsInput
                value={[...new Set(config?.commands?.skipping || [])]}
                onChange={(tags) => setConfig({...config, commands: {...config?.commands || {}, skipping: tags}})}
                inputProps={{maxLength: 15}}
            />

            <Text mt={4}>Komenda przyspieszenia wiadomości</Text>
            <TagsInput
                value={[...new Set(config?.commands?.continuing || [])]}
                onChange={(tags) => setConfig({...config, commands: {...config?.commands || {}, continuing: tags}})}
                inputProps={{maxLength: 15}}
            />

            <Text mt={4}>Gdy wyczerpano podpowiedzi</Text>
            <ListInputField items={config.hintsNoMore || []} onChange={(items) => setConfig({...config, hintsNoMore: items})} />

            <Text mt={4}>Gdy wyczerpano podpowiedzi, a pomijanie jest zablokowane</Text>
            <ListInputField items={config.hintsNoMoreWithoutBlocking || []} onChange={(items) => setConfig({...config, hintsNoMoreWithoutBlocking: items})} />
        </>
    );
}

export default StandardConfigComponent;
import React, {useEffect, useState} from "react";
import {Box, Flex, Icon, IconButton, useColorModeValue} from "@chakra-ui/react";
import {MdAdd, MdClose, MdUnfoldMore} from "react-icons/md";
import {arrayMove, sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {useIsMount} from "../../views/admin/scenario/components/Scenario";
import {v4 as uuid} from "uuid";
import DevButton from "../button/DevButton";
import {copyJson, pasteJson} from "../../contexts/DevContext";
import TextOrFileField from "./TextOrFileField";

const DragHandle = sortableHandle(() => <Icon w={6} h={6} pl={2} m={2} as={MdUnfoldMore} />);

const SortableItem = sortableElement(({key, value, onChange, onDelete, textColorPrimary, locked, allowFiles, ...rest}) => (
    <Flex py={1} w={'80%'} maxW={1000}>
        <TextOrFileField id={key} initialValue={value} onChange={v => onChange(v)} allowFiles={allowFiles ?? true} {...rest} />
        {!locked ? <DragHandle /> : ''}
        <IconButton textColor={textColorPrimary} variant={'ghost'} mx={0} h={6} p={1} my={2} display={locked ? 'none' : 'block'} icon={<MdClose/>} onClick={onDelete}></IconButton>
    </Flex>
));

const SortableContainer = sortableContainer(({children}) => {
    return <ul>{children}</ul>;
});

const ListInputField = ({id, items: initialItems, placeholder, onChange, allowFiles, locked}) => {
    const isMount = useIsMount();
    const [items, setItems] = useState(initialItems.map(i => ({id: uuid(), value: i})));
    const [input, setInput] = useState('');
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

    useEffect(() => {
        if (isMount) return;
        onChange(items.filter(i => !!i.value).map(i => i.value));
    }, [items]);

    const handleAdd = (v) => {
        if (v.trim() || input.trim()) {
            setItems([...items, {id: uuid(), value: v.trim() || input.trim()}]);
        }
    }

    const handleDelete = (index) => {
        setItems(items.filter((v, i) => i !== index));
    }

    const handleChange = (index, itemValue) => {
        setItems(items.map((v, i) => i === index ? ({...v, value: itemValue}) : v));
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        setItems(arrayMove(items, oldIndex, newIndex));
    };

    return (
        <Box my={2}>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle lockAxis={'y'}>
                {items.map(({id, value}, index) => (
                    <SortableItem
                        key={id}
                        index={index}
                        value={value}
                        textColorPrimary={textColorPrimary}
                        onChange={(itemValue) => handleChange(index, itemValue)}
                        onDelete={() => handleDelete(index)}
                        allowFiles={allowFiles ?? true}
                        locked={locked}
                    />
                ))}
            </SortableContainer>
            <Flex mt={1} display={locked ? 'none' : 'block'}>
                <TextOrFileField
                    id={id + 'i'}
                    initialValue={input}
                    onChange={v => setInput(v)}
                    onBlur={v => v && handleAdd(v)}
                    onEnter={v => v && handleAdd(v)}
                    placeholder={placeholder || '...'}
                    allowFiles={allowFiles}
                    clearOnBlur={true}
                    display={'inline-block'}
                />
                <IconButton variant={'ghost'} mx={0} h={6} my={2} p={1} icon={<MdAdd/>} onClick={() => handleAdd()}></IconButton>

                <DevButton mt={2} mr={2} pr={3} size='xs' onClick={() => copyJson(items.map(i => i.value))}>Kopiuj</DevButton>
                <DevButton mt={2} mr={2} pr={3} size='xs' onClick={() => pasteJson().then(arr => setItems(arr.map(i => ({id: uuid(), value: i}))))}>Wklej</DevButton>
            </Flex>
        </Box>
    )
}

export default ListInputField;

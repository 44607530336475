import React, {useEffect, useRef, useState} from 'react';
import {Box, Select, Text} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";

const VariableStepComponent = ({id, v, onChange, config, lite}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(v);
    const valueElement = useRef();

    const vars = (config?.variable?.vars || [])

    useEffect(() => {
        if (isMount) return;
        if (null === value.v && valueElement.current) {
            setValue({ ...value, v: 'true' === valueElement.current.value })
            return;
        }
        onChange(value);
    }, [value]);

    return (
        <Box display={'block'}>
            <Select
                w={0}
                minW={'200px'}
                display={'inline-block'}
                onChange={(e) => setValue({...value, k: e.target.value.toLowerCase(), v: null })}
                defaultValue={value.k}
                id={id + 'k'}
            >
                <option value={''}>Wybierz zmienną</option>
                {vars.filter(v => ['str', 'bool'].includes(v.type)).map(v => (
                    <option value={v.name}>{v.name}</option>
                ))}
            </Select>

            {value.k && (<Text mx={2} display={lite ? 'none' : 'inline-block'}>=</Text>)}

            {value.k && vars.find(v => v.name === value.k)?.type === 'bool' && (
                <Select
                    w={0}
                    minW={'200px'}
                    display={'inline-block'}
                    onChange={(e) => setValue({...value, v: 'true' === e.target.value})}
                    defaultValue={`${value.v}`}
                    ref={valueElement}
                    id={id + 'v'}
                >
                    <option value={'true'}>tak</option>
                    <option value={'false'}>nie</option>
                </Select>
            )}

            {/*{value.k && vars.find(v => v.name === value.k)?.type === 'str' && (*/}
            {/*    <Input*/}
            {/*        defaultValue={value.v}*/}
            {/*        onBlur={(e) => setValue({...value, v: e.target.value.toLowerCase()})}*/}
            {/*        onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}*/}
            {/*        display={lite ? 'none' : 'inline-block'}*/}
            {/*        id={id + 'v'}*/}
            {/*        w={0}*/}
            {/*        minW={'200px'}*/}
            {/*    />*/}
            {/*)}*/}
        </Box>
    );
}

export default VariableStepComponent;
import React, {createContext, useContext, useEffect} from 'react';
import {useAuth as useClerkAuth, useClerk, useUser} from '@clerk/clerk-react'

const AUTH_TOKEN_KEY = 'token';

export const tokens = {
    get: () => {
        try {
            return JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
        } catch (e) {
            console.error(e);
            return '';
        }
    },

    getServiceToken: () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('token') || '';
    },

    set: (token) => {
        localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(token));
    },

    parse: (token) => {
        try {
            var base64Url = (token || '').split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch (e) {
            return {};
        }
    }
}

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const auth = async (getToken) => {
    return getToken().then(token => {
        tokens.set(token);
        return token;
    });
}

const roles = () => {
    const token = tokens.get();
    const payload = tokens.parse(token);

    return payload.roles || [];
}

const hasRole = (role) => {
    return roles().includes(role);
}

export const AuthProvider = ({ children }) => {
    const {isSignedIn, isLoaded} = useUser();
    const {getToken} = useClerkAuth();
    const clerk = useClerk();

    useEffect(() => {
        if (isLoaded) {
            if (!isSignedIn) {
                tokens.set('');
                clerk.openSignIn();
            }
        }
    }, [isLoaded, isSignedIn, clerk]);

    return (
        <AuthContext.Provider value={{ awaitAuthentication: () => auth(getToken), hasRole, roles }}>
            {children}
        </AuthContext.Provider>
    );
};
import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      100: "#F0F0F0",
      200: "#6B6B6B",
      300: "#7A7A7A",
      400: "#8A8A8A",
      500: "#7A7A7A",
      600: "#5A5A5A",
      700: "#0E0E0E",
      800: "#2E2E2E",
      900: "#242424",
    },
    brandScheme: {
      100: "#F0F0F0",
      200: "#9B9B9B",
      300: "#ABABAB",
      400: "#BBBBBB",
      500: "#7A7A7A",
      600: "#5A5A5A",
      700: "#0E0E0E",
      800: "#2E2E2E",
      900: "#0E0E0E",
    },
    brandTabs: {
      100: "#F0F0F0",
      200: "#6B6B6B",
      300: "#7A7A7A",
      400: "#8A8A8A",
      500: "#7A7A7A",
      600: "#5A5A5A",
      700: "#0E0E0E",
      800: "#2E2E2E",
      900: "#0E0E0E",
    },
    secondaryGray: {
      100: "#E6E6E6",
      200: "#EBEBEB",
      300: "#F7F7F7",
      400: "#ECECEC",
      500: "#A3A3A3",
      600: "#B8B8B8",
      700: "#8A8A8A",
      800: "#8A8A8A",
      900: "#2E2E2E",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#E6E6E6",
      100: "#CCCCCC",
      200: "#C7C7C7",
      300: "#ABABAB",
      400: "#5E5E5E",
      500: "#3D3D3D",
      600: "#4A4A4A",
      700: "#2E2E2E",
      800: "#242424",
      900: "#1A1A1A",
    },
    gray: {
      50: "#FDFDFD",
      100: "#FAFAFA",
      200: "#C7C7C7",
      300: "#ABABAB",
      400: "#5E5E5E",
      500: "#3D3D3D",
      600: "#4A4A4A",
      700: "#2E2E2E",
      800: "#242424",
      900: "#1A1A1A",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("secondaryGray.300", "navy.900")(props),
        fontFamily: "DM Sans",
        letterSpacing: "-0.5px",
      },
      input: {
        color: mode("gray.700", "navy.100")(props),
      },
    }),
  },
};

import React, {useState} from 'react';
import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    useColorMode
} from "@chakra-ui/react";
import {scenarioApi} from "../../../../api";
import {useAuth} from "../../../../contexts/AuthProvider";
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer-continued';

const sanitizeSteps = (steps) =>
    JSON.stringify(
        steps.map(step => {
            let {id, _id, t, v, ...rest} = step;
            if (t === 'media') {
                if (typeof v === 'string') {
                    v = '...' + v.slice(-30) + '...';
                } else {
                    v = v._f || v.t;
                }
            }
            return {...rest, [t]: v};
        }),
        null,
        2,
    ).replaceAll(',\n', '\n') || '';

const ReviewComponent = ({ id, steps: initialSteps }) => {
    const [steps,] = useState(sanitizeSteps(initialSteps));
    const [remoteSteps, setRemoteSteps] = useState('');
    const [loaded, setLoaded] = useState(false);
    const {awaitAuthentication} = useAuth();
    const { colorMode } = useColorMode();

    React.useEffect(() => {
        const fetchData = async () => {
            const scenario = await scenarioApi({awaitAuthentication}).findById(id);
            const remoteSteps = sanitizeSteps(scenario.steps);
            setRemoteSteps(remoteSteps);
            setLoaded(true);
        };
        fetchData();
    }, []);

    return (
        <Box w={'100%'}>
            <Skeleton isLoaded={loaded} className={'diff'}>
                {steps !== remoteSteps ? <ReactDiffViewer
                    oldValue={remoteSteps}
                    newValue={steps}
                    compareMethod={DiffMethod.WORDS}
                    codeFoldMessageRenderer={(number) => `Rozwiń kolejnych ${number} linii...`}
                    splitView={true}
                    hideLineNumbers={true}
                    hideMarkers={true}
                    useDarkTheme={colorMode === 'dark'}
                    styles={{ diffContainer: { width: '100%', overflowX: 'hidden' } }}
                /> : 'Nie wykryto zmian scenariusza.'}
            </Skeleton>
        </Box>
    );
}

export default ReviewComponent;

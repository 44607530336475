import {Button, Flex, Input, Text,} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useIsMount} from "../../views/admin/scenario/components/Scenario";
import {useAuth} from "../../contexts/AuthProvider";
import {filesApi} from "../../api";

const FileField = ({id, initialValue, onChange}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState({filename: initialValue?.filename || '', token: initialValue?.token || ''});
    const [uploading, setUploading] = useState(false);
    const fileRef = React.useRef();
    const {awaitAuthentication} = useAuth();

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    const handleUpload = async () => {
        const file = fileRef.current.files[0];
        const formData = new FormData();
        formData.append('file', file);

        setUploading(true);

        try {
            const data = await filesApi({awaitAuthentication}).uploadFile(formData);
            setValue({
                token: data.token,
                filename: file.name,
                type: file.type,
                timestamp: new Date().toISOString(),
            });

            toast.success('Pomyślnie wgrano plik');
        } catch (error) {
            console.log(error)
            toast.error('Nie udało się wgrać pliku');
        }

        setUploading(false);
        fileRef.current.value = '';
    };

    return (
        <Flex>
            <Button
                onClick={() => fileRef.current.click()}
                variant="outline"
                isLoading={uploading}
            >
                {value.filename ? 'Zmień plik' : 'Wgraj plik'}
            </Button>
            <Text
                maxWidth={'300px'}
                fontSize={'md'}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                p={2}
            >{value.filename || '-'}</Text>
            <Input
                id={id + 'f'}
                type="file"
                ref={fileRef}
                style={{display: 'none'}}
                onChange={handleUpload}
            />
        </Flex>
    );
}

export default FileField;

import {Box, Button, Flex, Heading, Skeleton, Spacer,} from "@chakra-ui/react";
import React from "react";
import {useParams} from "react-router-dom";
import {MdCopyAll, MdSave, MdUpload} from "react-icons/md";
import Card from "../../../components/card/Card";
import {scenarioApi} from "../../../api";
import {useAuth} from "../../../contexts/AuthProvider";
import toast from "react-hot-toast";
import DevButton from "../../../components/button/DevButton";
import {copyJson, pasteJson} from "../../../contexts/DevContext";
import ScenarioLiteComponent from "./components/ScenarioLite";

export default function ScenarioEditorLite() {
    const scenarioId = useParams().id;

    const [scenario, setScenario] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const {awaitAuthentication} = useAuth();

    React.useEffect(() => {
        const fetchData = async () => {
            setSaving(true);
            const scenario = await scenarioApi({awaitAuthentication}).findById(scenarioId);
            setScenario(scenario);
            setLoading(false);
            setSaving(false);
        };
        fetchData();
    }, []);

    const onSave = async () => {
        await scenarioApi({awaitAuthentication})
            .update(scenarioId, {steps: scenario.steps})
            .then(() => {
                toast.success('Pomyślnie zapisano zmiany.')
            })
            .catch(() => toast.error('Nie udało się zapisać zmian. Spróbuj ponownie.'));
    }

    const onStepsUpdated = (steps) => {
        setScenario({...scenario, steps});
    }

    return (
        <Box marginBottom={'8em'}>
            <Card>
                <Skeleton isLoaded={!loading}>
                    <Flex mb={8}>
                        <Heading as={'h4'} size={'md'}>Scenariusz "{scenario?.name}"</Heading>
                        <DevButton onClick={() => copyJson(scenario.steps)} leftIcon={<MdCopyAll/>} size='xs'>Pobierz</DevButton>
                        <DevButton onClick={() => pasteJson().then(s => setScenario({...scenario, steps: s}))} leftIcon={<MdUpload/>} ml={1} mr={4} size='xs'>Załaduj</DevButton>
                        <Spacer/>
                        <Button onClick={onSave} leftIcon={<MdSave/>} variant="brand" disabled={saving} ml={1} position={'fixed'} right={{base: '5', md: '12'}} zIndex={999}>Zapisz</Button>
                    </Flex>
                    {scenario ? <ScenarioLiteComponent scenario={scenario} onStepsUpdated={onStepsUpdated} /> : null}
                </Skeleton>
            </Card>
        </Box>
    );
}

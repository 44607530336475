
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const buildHeaders = async (awaitAuthentication) => {
    const oauthToken = await awaitAuthentication();

    return {
        ...(oauthToken && {"authorization": "Bearer " + oauthToken}),
        // ...(serviceToken && {"x-auth-token": serviceToken}),
        "content-type": "application/json",
    }
}

export const scenarioApi = ({awaitAuthentication}) => {
    const withRetry = async (call) => {
        return call(await buildHeaders(awaitAuthentication)).then(async (response) => {
            if (response.status === 401) {
                await awaitAuthentication();

                return call(await buildHeaders(awaitAuthentication))
                    .then(response => response.json());
            }
            if (response.status === 403) {
                throw new Error();
            }
            return response.json();
        });
    };

    return {
        /**
         * @returns {Promise<[{name: string, steps: array}]>}
         */
        listAll: async () => {
            return withRetry(headers => fetch(`${baseUrl}/scenarios`, {headers}));
        },

        /**
         * @param id {string}
         * @returns {Promise<{name: string, steps: array}>}
         */
        findById: async (id) => {
            return withRetry(headers => fetch(`${baseUrl}/scenarios/${id}`, {headers}));
        },

        /**
         * @param id {string}
         * @param scenario {name: string, steps: array}
         * @returns {Promise<void>}
         */
        update: async (id, scenario) => {
            const body = JSON.stringify(scenario);
            return withRetry(headers => fetch(`${baseUrl}/scenarios/${id}`, {headers, body, method: "PATCH"})).then(() => {});
        },

        /**
         * @param scenario {name: string}
         * @returns {Promise<void>}
         */
        create: async (scenario) => {
            const body = JSON.stringify(scenario);
            return withRetry(headers => fetch(`${baseUrl}/scenarios`, {headers, body, method: "POST"})).then(() => {});
        },
    };
}

export const gamesApi = ({awaitAuthentication}) => {
    const withRetry = async (call) => {
        return call(await buildHeaders(awaitAuthentication)).then(async (response) => {
            if (response.status === 401) {
                await awaitAuthentication();

                return call(await buildHeaders(awaitAuthentication))
                    .then(response => response.json());
            }
            return response.json();
        });
    };

    return {
        createTestGame: async (scenarioId) => {
            const body = JSON.stringify({
                playerName: "Test " + new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
                playersCount: 1,
                scenarioId,
            });
            return withRetry(headers => fetch(`${baseUrl}/games-test`, {headers, body, method: "POST"}));
        },
    };
}

export const aiApi = ({awaitAuthentication}) => {
    return {
        process: async (steps, prompt) => {
            const body = JSON.stringify({steps, prompt});
            const headers = await buildHeaders(awaitAuthentication);

            return fetch(`${baseUrl}/ai`, {headers: headers, body, method: "POST", mode: 'cors'})
                .then(response => response.json())
                .then(response => {
                    if (response.error) {
                        throw new Error(response.error);
                    }
                    return response;
                })
                .catch(e => {
                    console.error(e);
                    throw new Error('AI service is not available.');
                });
        },
    };
}

export const chatApi = ({awaitAuthentication}) => {
    return {
        getAuth: async (userId) => {
            return fetch(`${baseUrl}/chat-auth?userId=${userId}`, {headers: await buildHeaders(awaitAuthentication)})
                .then(response => response.json());
        },
    }
}

export const filesApi = ({awaitAuthentication}) => {
    return {
        uploadFile: async (formData) => {
            const headers = await buildHeaders(awaitAuthentication);
            delete headers['content-type'];

            return fetch(`${baseUrl}/files`, { method: 'POST', body: formData, headers })
                .then(response => response.json())
                .then(response => {
                    if (response.error) {
                        throw new Error(response.error);
                    }
                    return response;
                })
                .catch(e => {
                    console.error(e);
                    throw new Error('Could not upload the file');
                });
        },
    }
};

// Chakra imports
import {Box} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import routes from 'routes.js';

// Custom Chakra theme
export default function ExternalDashboard(props) {
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/s') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}

			return null;
		});
	};
	return (
		<Box my={0}>
			<Box my={0} mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
				<Switch>
					{getRoutes(routes)}
				</Switch>
			</Box>
			<Box>
				<Footer />
			</Box>
		</Box>
	);
}

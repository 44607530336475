import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Code,
    Input,
    Spacer,
    Text
} from "@chakra-ui/react";
import {useIsMount} from "../Config";
import {nanoid} from "nanoid";
import ListInputField from "../../../../../components/fields/ListInputField";
import DevButton from "../../../../../components/button/DevButton";

const newId = () => nanoid(6);

const WrongsConfigComponent = ({initialConfig, onChange}) => {
    const isMount = useIsMount();
    const [config, setConfig] = useState(initialConfig);

    useEffect(() => {
        if (isMount) return;
        onChange(config);
    }, [config]);

    const handleNew = () => {
        const newWrongsObject = {
            id: newId(),
            name: '',
            answers: [],
        };

        setConfig({...config, extra: [...config.extra, newWrongsObject]});
    }

    return (
        <Accordion allowToggle>
            <AccordionItem border='none'>
                <AccordionButton _hover={{background: 'none'}} style={{boxShadow: 'none'}} px={0}>
                    <AccordionIcon mr={2} />
                    Domyślne
                    <Spacer/>
                </AccordionButton>
                <AccordionPanel pl={0} pb={4}>
                    <Text mt={4}>Odpowiedzi</Text>
                    <ListInputField items={config.default || []} onChange={(items) => setConfig({...config, default: items})} />
                </AccordionPanel>
            </AccordionItem>

            {(config.extra || []).map((v, i) => (
                <AccordionItem border='none' key={v.id} id={v.id}>
                    <AccordionButton _hover={{background: 'none'}} style={{boxShadow: 'none'}} px={0}>
                        <AccordionIcon mr={2} />
                        Pakiet &nbsp;<Code>{v.name || '...'}</Code>
                        <Spacer/>
                        <DevButton size={'xs'} onClick={() => setConfig({ ...config, extra: config.extra.filter((_, j) => j !== i) })}>Usuń</DevButton>
                    </AccordionButton>
                    <AccordionPanel pl={0} pb={4}>
                        <Text mt={4}>Nazwa pakietu</Text>
                        <Input defaultValue={v.name} onChange={e => setConfig({ ...config, extra: [...config.extra.slice(0, i), { ...config.extra[i], name: e.target.value}, ...config.extra.slice(i + 1)] })}></Input>

                        <Text mt={4}>Odpowiedzi</Text>
                        <ListInputField items={v.answers || []} onChange={(items) => setConfig({...config, extra: [...config.extra.slice(0, i), { ...config.extra[i], answers: items}, ...config.extra.slice(i + 1)]})} />
                    </AccordionPanel>
                </AccordionItem>
            ))}
            <DevButton variant={'outline'} onClick={handleNew} mt={4}>Dodaj pakiet</DevButton>

            {/*<Box><Code>{JSON.stringify(config.extra)}</Code></Box>*/}
        </Accordion>
    );
}

export default WrongsConfigComponent;

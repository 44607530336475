import React from "react";

// Chakra imports
import {Flex, Image, Text, useColorMode} from "@chakra-ui/react";

// Custom components
import {HSeparator} from "components/separator/Separator";

import moonstreetLogo from "assets/img/layout/moonstreet-alpha-black.png";

export function SidebarBrand() {
    const {colorMode} = useColorMode();
  return (
    <Flex align='center' direction='column'>
        <Image src={moonstreetLogo} px={8} pb={4} alt='Moonstreet' opacity={0.9} style={{filter: colorMode === 'dark' ? 'invert(1)' : 'invert(0)'}} />
        <Text fontSize={15} fontWeight={'black'} letterSpacing={0.5} mt={-5} mb={5} pt={0}>
            {process.env.REACT_APP_ENVIRONMENT === 'staging' ? 'STAGING' : ''}
            {process.env.REACT_APP_ENVIRONMENT === 'development' ? 'DEVELOPMENT' : ''}
        </Text>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;

import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@chakra-ui/react";
import Card from "components/card/Card";
import StepLite from "./StepLite";

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

const ScenarioLiteComponent = ({scenario, onStepsUpdated}) => {
    const isMount = useIsMount();
    const [steps, setSteps] = useState(scenario.steps || []);

    const onChange = (index, s) => {
        setSteps(items => items.map((item, i) => i === index ? s : item))
    }

    useEffect(() => {
        if (isMount) return;
        onStepsUpdated(steps);
    }, [steps]);

    return (
        <Box w={'100%'}>
            {steps.map((step, i) =>
                <Card w='100%' p={1} py={2} pr={4} my={2} boxShadow='base'>
                    <StepLite i={i + 1}
                          item={step}
                          onChange={(s) => onChange(i, s)}
                          config={scenario.config}
                    />
                </Card>
            )}
        </Box>
    );
}

export default ScenarioLiteComponent;

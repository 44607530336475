import {
    MdCallSplit,
    MdChat,
    MdLiveHelp,
    MdMoveUp,
    MdOutlineChat,
    MdOutlineFunctions,
    MdOutlineInput,
    MdOutlineLocalActivity,
    MdOutlineMms,
    MdOutlineQuiz,
    MdOutlineTimer,
    MdPowerSettingsNew
} from "react-icons/md";
import MessageStepComponent from "../components/steps/Message";
import InfoStepComponent from "../components/steps/Info";
import RiddleStepComponent from "../components/steps/Riddle";
import ChoiceStepComponent from "../components/steps/Choice";
import DelayStepComponent from "../components/steps/Delay";
import MediaStepComponent from "../components/steps/Media";
import GiftStepComponent from "../components/steps/Gift";
import InputStepComponent from "../components/steps/Input";
import QuestionStepComponent from "../components/steps/Question";
import StartStepComponent from "../components/steps/Start";
import FinishStepComponent from "../components/steps/Finish";
import VariableStepComponent from "../components/steps/Variable";
import JumpStepComponent from "../components/steps/Jump";

export const getChatUrl = (id) => process.env.REACT_APP_ENVIRONMENT === 'production' ?
    `https://chat.moonstreet.pl/${id}` :
    `https://staging-chat.moonstreet.pl/${id}`;

export const components = {
    message: {
        name: 'Wiadomość',
        initialValue: 'Tekst',
        shortcut: v => (typeof v === 'string' ? v : v.t).slice(0, 200) + ((typeof v === 'string' ? v : v.t).length > 200 ? '...' : ''),
        icon: MdOutlineChat,
        component: MessageStepComponent,
        lite: true,
    },
    info: {
        name: 'Informacja',
        initialValue: 'Tekst',
        shortcut: v => v.slice(0, 200) + (v.length > 200 ? '...' : ''),
        icon: MdChat,
        component: InfoStepComponent,
        lite: true,
    },
    riddle: {
        name: 'Zagadka',
        initialValue: { a: [], h: [], wa: [] },
        shortcut: v => '' + (v.a || []).slice(0, 3).join(', ').toUpperCase() + '...',
        icon: MdOutlineQuiz,
        component: RiddleStepComponent,
        lite: true,
    },
    start: {
        name: 'Rozpoczęcie',
        initialValue: { k: ['start'], wa: 'Aby rozpocząć grę, wyślij *START*.' },
        shortcut: v => 'Oczekiwanie na rozpoczęcie gry',
        icon: MdPowerSettingsNew,
        component: StartStepComponent,
        lite: true,
    },
    choice: {
        name: 'Wybór',
        initialValue: { o: [{a: []}], h: [] },
        shortcut: v => 'Wybór ścieżki',
        icon: MdCallSplit,
        component: ChoiceStepComponent,
        lite: true,
    },
    delay: {
        name: 'Opóźnienie',
        initialValue: '10',
        shortcut: v => v + ' sekund',
        icon: MdOutlineTimer,
        component: DelayStepComponent,
    },
    media: {
        name: 'Załącznik',
        initialValue: '#',
        shortcut: v => 'Załącznik ' + (typeof v === 'string' ? '' : '"' + (v._f || '') + '"'),
        icon: MdOutlineMms,
        component: MediaStepComponent,
    },
    gift: {
        name: 'Rekwizyt',
        initialValue: { id: 'x', n: '1' },
        shortcut: v => 'Rekwizyt "' + v.id?.toUpperCase() + '"',
        icon: MdOutlineLocalActivity,
        component: GiftStepComponent,
    },
    input: {
        name: 'Odczyt',
        initialValue: { v: 'x', a: [] },
        shortcut: v => 'Odczyt wartości "' + (typeof v === 'string' ? v.toLowerCase() : v.v.toLowerCase()) + '"',
        icon: MdOutlineInput,
        component: InputStepComponent,
        lite: true,
    },
    question: {
        name: 'Ankieta',
        initialValue: {q: 'Czy...', id: 'x'},
        shortcut: v => 'Pytanie "' + (v.q || '') + '"',
        icon: MdLiveHelp,
        component: QuestionStepComponent,
        lite: true,
    },
    finish: {
        name: 'Zakończenie',
        initialValue: null,
        shortcut: v => 'Zakończenie gry',
        icon: MdPowerSettingsNew,
        component: FinishStepComponent,
    },
    variable: {
        name: 'Zmienna',
        initialValue: {k: '', v: ''},
        shortcut: v => 'Ustawienie wartości ' + v.k.toUpperCase(),
        icon: MdOutlineFunctions,
        component: VariableStepComponent,
    },
    jump: {
        name: 'Skok',
        initialValue: {id: ''},
        shortcut: v => 'Wykonanie skoku',
        icon: MdMoveUp,
        component: JumpStepComponent,
    },
};


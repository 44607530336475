import {Box, Button, Flex, Heading, IconButton, Link, Skeleton, Spacer,} from "@chakra-ui/react";
import React from "react";
import {Link as RouterLink, useParams} from "react-router-dom";
import {MdArrowBack, MdCopyAll, MdSave, MdSettings, MdUpload} from "react-icons/md";
import Card from "../../../components/card/Card";
import {scenarioApi} from "../../../api";
import {useAuth} from "../../../contexts/AuthProvider";
import toast from "react-hot-toast";
import ConfigComponent from "./components/Config";
import {copyJson, pasteJson} from "../../../contexts/DevContext";
import DevButton from "../../../components/button/DevButton";

export default function ScenarioConfigEditor() {
    const scenarioId = useParams().id;

    const [scenarioName, setScenarioName] = React.useState('');
    const [config, setConfig] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const {awaitAuthentication, hasRole} = useAuth();

    React.useEffect(() => {
        const fetchData = async () => {
            setSaving(true);
            const scenario = await scenarioApi({awaitAuthentication}).findById(scenarioId);
            setConfig(scenario.config || {});
            setScenarioName(scenario.name);
            setLoading(false);
            setSaving(false);
        };
        fetchData();
    }, []);

    const onSave = async () => {
        console.log(config);
        await scenarioApi({awaitAuthentication})
            .update(scenarioId, {config})
            .then(() => {
                toast.success('Pomyślnie zapisano zmiany.')
            })
            .catch(() => toast.error('Nie udało się zapisać zmian. Spróbuj ponownie.'));
    }

    return (
        <Box marginBottom={'8em'}>
            <Card>
                <Skeleton isLoaded={!loading} opacity={loading ? 0.2 : 1} variant="shine">
                    <Flex mb={8}>
                        <IconButton icon={<MdArrowBack/>} as={RouterLink} to={'/admin/scenarios'} variant={'ghost'} size={'xs'} mr={2}></IconButton>
                        <Heading as={'h4'} size={'md'}>{scenarioName}&nbsp;</Heading>
                        <DevButton leftIcon={<MdSettings/>} mx={2} size={'xs'}>
                            <Link as={RouterLink} to={'/admin/scenario/' + scenarioId}>Szczegóły</Link>
                        </DevButton>
                        <DevButton size={'xs'} onClick={() => copyJson(config)} leftIcon={<MdCopyAll/>} >Pobierz</DevButton>
                        <DevButton size={'xs'} onClick={() => pasteJson().then(c => setConfig(c))} leftIcon={<MdUpload/>} ml={1} mr={4}>Załaduj</DevButton>
                        <Spacer/>
                        <Button onClick={onSave} leftIcon={<MdSave/>} variant="brand" disabled={saving || (!hasRole('admin') && !hasRole('org:manager') && !hasRole('editor') && !hasRole('org:editor') && !hasRole('editor:' + scenarioId))} ml={1} position={'fixed'} right={{base: '5', md: '10'}} zIndex={999}>Zapisz</Button>
                    </Flex>
                    <ConfigComponent initialConfig={config} onConfigUpdated={c => setConfig({ ...config, ...c })}/>
                </Skeleton>
            </Card>
        </Box>
    );
}

import React, {useEffect, useState} from 'react';
import {sortableHandle} from "react-sortable-hoc";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Flex,
    Heading,
    IconButton,
    Spacer,
    Text,
    theme
} from "@chakra-ui/react";
import {MdDeleteOutline, MdOutlineHelpOutline, MdUnfoldMore} from "react-icons/md";
import ConditionComponent from "./Condition";
import {useIsMount} from "./Scenario";
import {components} from "../variables/vars";

export const stringToColor = (str) => {
    const colors = Object.keys(theme.colors).filter(color => {
        const colorObj = theme.colors[color];
        if (typeof colorObj !== 'object' || !colorObj[500]) return false;

        const hex = colorObj[500].replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);

        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        return brightness < 200;
    });

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 10) - hash);
    }

    const colorIndex = Math.abs(hash) % colors.length;

    return theme.colors[colors[colorIndex]][500];
}

const DragHandle = sortableHandle(() =>
    <IconButton
        aria-label="Przesuń"
        background={'none'}
        _hover={{background: 'none'}}
        _active={{background: 'none'}}
        icon={<MdUnfoldMore/>}
    />);

const StepComponent = ({i, item, onChange, onRemoveClicked, onAddNewBelowClicked, config, getStepsIds}) => {
    const isMount = useIsMount();
    const [step, setStep] = useState(item);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleRemove = () => {
        onRemoveClicked();
    }

    const handleCustomId = (cid) => {
        setStep({ ...step, cid });
    }

    const handleExpand = (i) => {
        if (i === 0) {
            setIsExpanded(true)
        } else {
            setTimeout(() => setIsExpanded(false), 500);
        }
    }

    useEffect(() => {
        if (isMount) return;
        onChange(step);
    }, [step]);

    const StepComponent = components[item.t]?.component || (() => <></>);
    const IconComponent = components[item.t]?.icon || (() => <MdOutlineHelpOutline></MdOutlineHelpOutline>);
    const conditionalColor = item.c ? stringToColor(JSON.stringify(item.c)) : '';

    return (
        <>
            <Flex item={item.id}>
                <Heading p={3}  fontWeight="thin" opacity={0.8} size={'xs'}>
                    #{i}
                </Heading>
                <IconButton pl={0} mr={2} ml={-2}
                            variant={'ghost'}
                            aria-label={item.name}
                            opacity={0.7}
                            icon={<IconComponent size={18} />}
                />

                <Accordion allowToggle w={'100%'} onChange={handleExpand}>
                    <AccordionItem border='none'>
                        <Flex>
                            <AccordionButton _hover={{background: 'none'}} style={{boxShadow: 'none'}} className={'step-btn-' + item.id} px={0} py={0}>
                                <Text overflow={'hidden'} textOverflow={'clip'} pr={8} align={'left'} noOfLines={1} opacity={item.t === 'custom' ? 0.5 : 1}>
                                    {components[item.t]?.shortcut(step.v) ?? '?'}
                                </Text>
                            </AccordionButton>
                            <Spacer/>
                            <DragHandle/>
                            <IconButton icon={<MdDeleteOutline/>} variant={'ghost'} onClick={() => window.confirm('Potwierdzasz usunięcie?') && handleRemove()}>Usuń</IconButton>
                        </Flex>
                        <AccordionPanel pl={0} pb={4}>
                            {isExpanded ? <>
                                <StepComponent id={step.id} v={step.v} onChange={(v) => {
                                    setStep({ ...step, v })
                                    console.log(v)
                                }} config={config} getStepsIds={getStepsIds} />
                                <ConditionComponent id={step.id} mt={8}
                                                    borderLeft={'4px solid ' + (conditionalColor || '#000000') + (conditionalColor ? '88' : '44')}
                                                    value={step.c}
                                                    vars={(config?.variable?.vars || [])} onChange={(c) => {
                                    setStep({ ...step, c })
                                    console.log(JSON.stringify(c))
                                } }/>
                            </> : ''}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </Flex>
        </>
    );
};

export default StepComponent;

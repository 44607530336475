import React, {useEffect, useRef, useState} from 'react';
import {Box, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import StandardConfigComponent from "./config/Standard";
import ProgressConfigComponent from "./config/Progress";
import VariableConfigComponent from "./config/Variable";
import WrongsConfigComponent from "./config/Wrongs";

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

const ConfigComponent = ({initialConfig, onConfigUpdated}) => {
    const isMount = useIsMount();
    const [config, setConfig] = useState(initialConfig);

    useEffect(() => {
        if (isMount) return;
        console.log('Config modified')
        onConfigUpdated(config);
    }, [config]);

    return (
        <Box w={'100%'}>
            <Tabs variant='enclosed'>
                <TabList>
                    <Tab style={{boxShadow: 'none'}}>Ustawienia ogólne</Tab>
                    <Tab style={{boxShadow: 'none'}}>Ustawienia złych odpowiedzi</Tab>
                    <Tab style={{boxShadow: 'none'}}>Ustawienia postępu</Tab>
                    <Tab style={{boxShadow: 'none'}}>Ustawienia zmiennych</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <StandardConfigComponent initialConfig={config.standard || {}} onChange={c => setConfig({...config, standard: c})} />
                    </TabPanel>
                    <TabPanel>
                        <WrongsConfigComponent initialConfig={config.wrongs || {}} onChange={c => setConfig({...config, wrongs: c})} />
                    </TabPanel>
                    <TabPanel>
                        <ProgressConfigComponent initialConfig={config.progress || {}} onChange={c => setConfig({...config, progress: c})} />
                    </TabPanel>
                    <TabPanel>
                        <VariableConfigComponent initialConfig={config.variable || {}} onChange={c => setConfig({...config, variable: c})} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}

export default ConfigComponent;

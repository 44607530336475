import {Box, IconButton,} from "@chakra-ui/react";
import React from "react";
import {Link as RouterLink, useParams} from "react-router-dom";
import Card from "../../../components/card/Card";
import ChatComponent from "./Chat";
import {MdArrowBack} from "react-icons/md";

export default function ChatView() {
    const scenarioId = useParams().scenarioId;
    const gameId = useParams().gameId;

    return (
        <Box>
            <Card w={'100%'}>
                <IconButton icon={<MdArrowBack/>} as={RouterLink} to={'/admin/scenario/' + scenarioId} variant={'ghost'} size={'xs'} mr={2}></IconButton>
                <ChatComponent styles={{height: 500}} userId={gameId}/>
            </Card>
        </Box>
    );
}

import React from 'react';
import {Box} from "@chakra-ui/react";

const FinishStepComponent = () => {

    return (
        <Box height={0} mb={-8}>
        </Box>
    );
}

export default FinishStepComponent;
import React, {useCallback, useEffect} from 'react';
import {Chatbox, Session} from "@talkjs/react";
import Talk from "talkjs";
import {chatApi} from "../../../api";
import {useAuth} from "../../../contexts/AuthProvider";

const ChatComponent = ({ userId, styles }) => {
    const [appId, setAppId] = React.useState(null);
    const {awaitAuthentication} = useAuth();

    const syncConversation = useCallback((session) => {
        const conversation = session.getOrCreateConversation(userId);
        conversation.setParticipant(session.me);
        return conversation;
    }, [userId]);

    const getToken = async () => {
        return chatApi({awaitAuthentication})
            .getAuth(userId)
            .then(({token, appId}) => {
                setAppId(appId);
                return token;
            })
            .catch(() => {
                return null;
            })
    }

    useEffect(() => {
        getToken();
    }, []);

    return (appId) && (
        <Session appId={appId} syncUser={new Talk.User({ id: userId, name: 'Admin' })} tokenFetcher={getToken}>
            <Chatbox theme={'default'} showChatHeader={false} syncConversation={syncConversation} style={styles} />
        </Session>
    );
};

export default ChatComponent;
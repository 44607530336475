import React, {useEffect, useState} from 'react';
import {Input, Text, Textarea} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";

const GiftStepComponent = ({id, v, onChange}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    return (
        <>
            ID:
            <Input
                defaultValue={value.id}
                textTransform={'uppercase'}
                onBlur={(e) => setValue({ ...value, id: e.target.value.toLowerCase() })}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                id={id + 'id'}
            />

            <Text mt={4}>Ilość:</Text>
            <Input
                defaultValue={value.n}
                onBlur={(e) => setValue({ ...value, n: e.target.value })}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                id={id + 'n'}
            />
        </>
    );
}

export default GiftStepComponent;
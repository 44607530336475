import React, {useEffect, useState} from "react";
import {Table, TableContainer, Tbody, Td, Tr} from "@chakra-ui/react";
import FileField from "./FileField";
import {useIsMount} from "../../views/admin/scenario/components/Scenario";

const FileMapField = ({id, fields, initialItems, onChange}) => {
    const isMount = useIsMount();
    const [items, setItems] = useState(fields.reduce((acc, {key}) => ({...acc, [key]: initialItems[key] ?? {}}), {}));

    useEffect(() => {
        if (isMount) return;
        onChange(items);
    }, [items]);

    const handleChange = (key, value) => {
        console.log(value)
        setItems({...items, [key]: value});
    }

    return (
        <>
            <TableContainer>
                <Table variant='simple' size={'sm'}>
                    <Tbody>
                        {fields.map(({key, label}) => (
                            <Tr>
                                <Td width={0}>
                                    {label}
                                </Td>
                                <Td>
                                    <FileField id={id + key} initialValue={items[key]} onChange={value => handleChange(key, value)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default FileMapField;
import React, {useEffect, useState} from 'react';
import {TagsInput} from "react-tag-input-component";
import {Text, Textarea} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";

const StartStepComponent = ({id, v, onChange, lite}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    return (
        <>
            <Text display={lite ? 'none' : 'block'}>
                Frazy rozpoczynające:
            </Text>
            <TagsInput
                value={[...new Set(value.k)]}
                onChange={(tags) => setValue({...value, k: tags})}
                inputProps={{maxLength: 15}}
                name={id}
            />

            <Text display={lite ? 'none' : 'block'} mt={2}>
                Wiadomość:
            </Text>
            <Textarea
                defaultValue={value.wa || ''}
                onBlur={(e) => setValue({...value, wa: e.target.value})}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                name={id + 'wa'}
                mt={2}
            />
        </>
    );
}

export default StartStepComponent;
import {
    Box,
    Button,
    Flex,
    Heading,
    IconButton,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Spacer,
} from "@chakra-ui/react";
import React from "react";
import ScenarioComponent from "./components/Scenario";
import {Utils} from "@react-awesome-query-builder/core";
import {BasicConfig} from "@react-awesome-query-builder/ui";
import {Link as RouterLink, useParams} from "react-router-dom";
import {MdArrowBack, MdCopyAll, MdDownload, MdNewLabel, MdSave, MdSettings} from "react-icons/md";
import Card from "../../../components/card/Card";
import {gamesApi, scenarioApi} from "../../../api";
import ReviewComponent from "./components/Review";
import {useAuth} from "../../../contexts/AuthProvider";
import toast from "react-hot-toast";
import DevButton from "../../../components/button/DevButton";
import {copyJson, pasteJson} from "../../../contexts/DevContext";
import {IoMdCog} from "react-icons/io";
import ScenarioProcessor from "./components/Processor";
import domtoimage from 'dom-to-image';
import ChatComponent from "../chat/Chat";

const config = {
    ...BasicConfig,
    fields: {
        path: {
            label: 'droga',
            type: 'text',
        }
    }
}

export const jsonLogicToString = (jsonLogic) => {
    return Utils.queryString(Utils.loadFromJsonLogic(jsonLogic, config), config, true);
}

export default function ScenarioEditor() {
    const scenarioId = useParams().id;

    const [scenario, setScenario] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [reviewing, setReviewing] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [testGameId, setTestGameId] = React.useState(null);
    const {awaitAuthentication, hasRole} = useAuth();
    const reviewElement = React.useRef(null);

    React.useEffect(() => {
        const fetchData = async () => {
            setSaving(true);
            const scenario = await scenarioApi({awaitAuthentication}).findById(scenarioId);
            setScenario(scenario);
            setLoading(false);
            setSaving(false);
        };
        fetchData();
    }, []);

    const onSave = async () => {
        if (reviewing) {
            await scenarioApi({awaitAuthentication})
                .update(scenarioId, {steps: scenario.steps})
                .then(() => {
                    setReviewing(false)
                    toast.success('Pomyślnie zapisano zmiany.')
                })
                .catch(() => toast.error('Nie udało się zapisać zmian. Spróbuj ponownie.'));
        } else {
            setReviewing(true);
        }
    }

    const onStepsProcessed = async (steps) => {
        setScenario({...scenario, steps});
        await new Promise(r => setTimeout(r, 1000));
        setProcessing(false);
        await onSave();
    }

    const onStepsUpdated = (steps) => {
        setScenario({...scenario, steps});
    }

    const handleGenerate = async (event) => {
        event.target.disabled = true;
        await gamesApi({awaitAuthentication})
            .createTestGame(scenario.id)
            .then(({id}) => {
                event.target.disabled = false;
                setTestGameId(id);
            })
            .catch(() => {
                event.target.disabled = false;
                toast.error('Nie udało się wygenerować gry. Spróbuj ponownie.')
            });
    }

    const handleExportReview = async () => {
        const filename = 'Zmiany ' + scenario.name + ' z ' + new Date().toLocaleTimeString() + '.jpg';

        const data = await domtoimage.toJpeg(reviewElement.current, {quality: 0.75})
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = filename
        fakeLink.href = data;
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        fakeLink.remove();
    };

    return (
        <Box marginBottom={'8em'}>
            <Modal isOpen={testGameId} onClose={() => { setTestGameId(null); }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Gra testowa
                        {/*<IconButton icon={<MdOpenInNew/>} as={RouterLink} to={'/admin/scenario/' + scenarioId + '/test-game/' + testGameId} variant={'outline'} size={'xs'} ml={2}>Otwórz w nowej karcie</IconButton>*/}
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody p={0} mb={4}>
                        {testGameId && (<ChatComponent styles={{height: 500}} userId={testGameId} />)}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Card>
                <Skeleton isLoaded={!loading} opacity={loading ? 0.2 : 1} variant="shine">
                    <Flex mb={8}>
                        <IconButton icon={<MdArrowBack/>} as={RouterLink} to={'/admin/scenarios'} variant={'ghost'} size={'xs'} mr={2}></IconButton>
                        <Heading as={'h4'} size={'md'}>{scenario?.name}</Heading>
                        <Button onClick={handleGenerate} leftIcon={<MdNewLabel/>} disabled={saving} variant='outline' mx={2} size={'xs'}>Gra testowa</Button>
                        <DevButton leftIcon={<MdSettings/>} mx={2} size={'xs'}>
                            <Link as={RouterLink} to={'/admin/scenario/' + scenario?.id + '/config'}>Konfiguracja</Link>
                        </DevButton>
                        <DevButton onClick={() => copyJson(scenario.steps)} leftIcon={<MdCopyAll/>} size='xs'>Eksportuj</DevButton>
                        <DevButton onClick={() => pasteJson().then(s => setScenario({...scenario, steps: s}))} leftIcon={<MdDownload/>} ml={1} mr={4} size='xs'>Importuj</DevButton>
                        <DevButton onClick={() => setProcessing(true)} leftIcon={<IoMdCog/>} size='xs'>Przetwarzaj</DevButton>
                        <Spacer/>
                        {reviewing ? <Button onClick={() => setReviewing(false)} leftIcon={<MdArrowBack/>} mr={'7em'} variant="outline">Wróć do edycji</Button> : ''}
                        <Button onClick={onSave} leftIcon={<MdSave/>} variant="brand" disabled={saving || (!hasRole('admin') && !hasRole('org:manager') && !hasRole('editor') && !hasRole('org:editor') && !hasRole('editor:' + scenario?.id))} ml={1} position={'fixed'} right={{base: '5', md: '10'}} zIndex={999}>Zapisz</Button>
                    </Flex>
                    {scenario ? <ScenarioComponent scenario={scenario} onStepsUpdated={onStepsUpdated} /> : null}

                    <Modal isOpen={reviewing} size={'5xl'} scrollBehavior={'inside'}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader textAlign={'right'}>
                                <DevButton size={'xs'} onClick={handleExportReview} mr={2}>Eksportuj</DevButton>
                                {reviewing ? <Button onClick={() => setReviewing(false)} leftIcon={<MdArrowBack/>} mr={'5px'} variant="outline">Wróć do edycji</Button> : ''}
                                <Button onClick={onSave} leftIcon={<MdSave/>} variant="brand" disabled={saving}>Zapisz</Button>
                            </ModalHeader>
                            <ModalBody>
                                <Box ref={reviewElement}>
                                    <ReviewComponent id={scenario?.id} steps={scenario?.steps}></ReviewComponent>
                                </Box>
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    <ScenarioProcessor steps={scenario?.steps || []} open={processing} onClose={() => setProcessing(false)} onChange={onStepsProcessed} />
                </Skeleton>
            </Card>
        </Box>
    );
}

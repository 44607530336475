import React, {useEffect, useRef, useState} from 'react';
import {sortableContainer, sortableElement} from "react-sortable-hoc";
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer
} from "@chakra-ui/react";
import Step, {stringToColor} from "./Step";
import Card from "components/card/Card";
import {HSeparator} from "../../../../components/separator/Separator";
import {components} from "../variables/vars";
import {MdOutlineHelpOutline} from "react-icons/md";
import {isDevMode} from "../../../../contexts/DevContext";
import {customAlphabet} from "nanoid";
import {useColorMode} from "@chakra-ui/color-mode";

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 8);

export function arrayMoveMutable(array, fromIndex, toIndex) {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

        const [item] = array.splice(fromIndex, 1);
        array.splice(endIndex, 0, item);
    }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
    const newArray = [...array];
    arrayMoveMutable(newArray, fromIndex, toIndex);
    return newArray;
}

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

const boostrapStep = (type) => {
    return {
        id: nanoid(),
        t: type,
        v: components[type].initialValue || '',
    };
}

const SortableItem = sortableElement(({i, value, onChange, onRemoveClicked, onAddNewBelowClicked, config, getStepsIds}) => {
    const {colorMode} = useColorMode();
    const conditionalColor = value.c ? stringToColor(JSON.stringify(value.c)) : '';

    return (
        <>
        <Card w='100%' p={1} py={2} my={2} boxShadow={colorMode === 'dark' ? 'lg' : 'base'}
            borderRadius={14} borderLeft={'4px solid ' + (conditionalColor || '#000000') + (conditionalColor ? '88' : '00')}>
            <Step i={i} item={value}
                  onChange={onChange}
                  onRemoveClicked={onRemoveClicked}
                  onAddNewBelowClicked={onAddNewBelowClicked}
                  config={config}
                  getStepsIds={getStepsIds}
            />
        </Card>
            <Flex justify={'left'} my={-4} ml={-3} opacity={0.07} width={'100%'} _hover={{opacity: 0.5}}>
                <Button size={'sm'} variant={'ghost'} onClick={onAddNewBelowClicked}>+</Button>
            </Flex>
        </>
    )
});

const SortableContainer = sortableContainer(({children}) => {
    return <Box>
        {children}
    </Box>;
});

const ScenarioComponent = ({scenario, onStepsUpdated}) => {
    const isMount = useIsMount();
    const [steps, setSteps] = useState(scenario.steps || []);
    const [addNewStepBelow, setAddNewStepBelow] = useState(null);
    const [recentlyAddedStep, setRecentlyAddedStep] = useState(null);

    const onSortEnd = ({oldIndex, newIndex}) => {
        setSteps(items => arrayMoveImmutable(items, oldIndex, newIndex));
    };

    const onRemoveClicked = (index) => {
        setSteps(items => items.filter((item, i) => i !== index))
    }

    const onAddNewBelowClicked = (index) => {
        setAddNewStepBelow(index);
    }

    const onChange = (index, s) => {
        setSteps(items => items.map((item, i) => i === index ? s : item))
    }

    const handleNewClicked = (type) => {
        let step = boostrapStep(type);
        setRecentlyAddedStep(step);

        if (addNewStepBelow !== null) {
            setSteps(items => [...items.slice(0, addNewStepBelow + 1), step, ...items.slice(addNewStepBelow + 1)]);
        } else {
            setSteps(items => [...items, step]);
        }

        setAddNewStepBelow(null);
    }

    useEffect(() => {
        if (isMount) return;

        if (recentlyAddedStep) {
            setTimeout(() => {
                document.querySelector('.step-btn-' + recentlyAddedStep.id)?.click();
                setRecentlyAddedStep(null);
            }, 100);
        }

        const timeout = setTimeout(() => {
            console.log('Scenario modified')
            onStepsUpdated(steps)
        }, 1000);
        return () => clearTimeout(timeout);
    }, [steps]);

    const renderStepButton = (type, rest) => {
        const IconComponent = components[type]?.icon || (() => <MdOutlineHelpOutline></MdOutlineHelpOutline>);
        return (
            <Button mb={2} variant={'outline'} w={'100%'} {...rest} leftIcon={<IconComponent/>} onClick={() => handleNewClicked(type)}>{components[type]?.name || '?'}
            </Button>
        )
    }

    return (
        <Box w={'100%'}>
            <Modal isOpen={addNewStepBelow !== null} closeOnOverlayClick={true}
                   onClose={() => setAddNewStepBelow(null)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Nowy krok</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {renderStepButton('message')}
                        {renderStepButton('riddle')}
                        {renderStepButton('media')}
                        <HSeparator bg={'none'} mb={4}/>
                        {renderStepButton('choice')}
                        {renderStepButton('input')}
                        {renderStepButton('variable')}
                        {renderStepButton('jump')}
                        {renderStepButton('delay')}
                        {isDevMode() && renderStepButton('info')}
                        {isDevMode() && renderStepButton('question')}
                        {isDevMode() && renderStepButton('gift')}
                        {isDevMode() && renderStepButton('start')}
                        {isDevMode() && renderStepButton('finish')}
                    </ModalBody>
                </ModalContent>
            </Modal>

            <SortableContainer onSortEnd={onSortEnd} useDragHandle lockAxis={'y'}>
                {steps.map((step, i) =>
                    <SortableItem
                        key={`item-${step.id}`}
                        i={i + 1}
                        index={i}
                        value={step}
                        onChange={(s) => onChange(i, s)}
                        onRemoveClicked={() => onRemoveClicked(i)}
                        onAddNewBelowClicked={() => onAddNewBelowClicked(i)}
                        config={scenario.config}
                        getStepsIds={() => steps.map(s => ({id: s.id, type: s.t, index: steps.indexOf(s)}))}
                    />
                )}
            </SortableContainer>

            <Flex opacity={0.5} _hover={{opacity: 0.8}} mt={4} width={'50%'} justifySelf={'center'} minW={'400px'}>
                {renderStepButton('message', {variant: 'ghost'})}
                {renderStepButton('riddle', {variant: 'ghost'})}
                {renderStepButton('media', {variant: 'ghost'})}
            </Flex>
            <Flex mt={8}>
                <Spacer/>
                <Button variant={'outline'} onClick={() => setAddNewStepBelow(steps.length - 1)}>Dodaj krok</Button>
            </Flex>
        </Box>
    );
}

export default ScenarioComponent;

import React, {useEffect, useState} from "react";
import {Input, Table, TableContainer, Tbody, Td, Tr} from "@chakra-ui/react";

const MapField = ({id, fields, initialItems, onChange}) => {
    const [items, setItems] = useState(fields.reduce((acc, {key}) => ({...acc, [key]: initialItems[key] ?? ''}), {}));

    useEffect(() => {
        onChange(items);
    }, [items]);

    const handleChange = (key, value) => {
        setItems({...items, [key]: value});
    }

    return (
        <>
            <TableContainer>
                <Table variant='simple' size={'sm'}>
                    <Tbody>
                        {fields.map(({key, label}) => (
                            <Tr>
                                <Td width={0}>
                                    {label}
                                </Td>
                                <Td>
                                    <Input key={id + key} defaultValue={items[key]} onChange={e => handleChange(key, e.target.value)}></Input>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MapField;
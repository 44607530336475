import React, {useEffect, useState} from 'react';
import {Input, Text} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";

const QuestionStepComponent = ({id, v, onChange, lite}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(typeof v === 'string' ? {q: v, id: ''} : v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    return (
        <>
            <Text display={lite ? 'none' : 'block'}>
                Pytanie:
            </Text>
            <Input
                defaultValue={value.q || ''}
                onBlur={(e) => setValue({ ...value, q: e.target.value })}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                name={id + 'q'}
            />

            <Text display={lite ? 'none' : 'block'} mt={4}>Zapisz jako:</Text>
            <Input
                defaultValue={value.id || ''}
                onBlur={(e) => setValue({ ...value, id: e.target.value.toLowerCase() })}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                id={id + 'id'}
                display={lite ? 'none' : 'block'}
            />
        </>
    );
}

export default QuestionStepComponent;
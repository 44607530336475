import React, {useEffect, useState} from 'react';
import {Input, Text} from "@chakra-ui/react";
import {useIsMount} from "../Config";
import {TagsInput} from "react-tag-input-component";

const ProgressConfigComponent = ({initialConfig, onChange}) => {
    const isMount = useIsMount();
    const [config, setConfig] = useState(initialConfig);

    useEffect(() => {
        if (isMount) return;
        onChange(config);
    }, [config]);

    return (
        <>
            <Text>Komenda postępu gry</Text>
            <TagsInput
                value={[...new Set(config.commands || [])]}
                onChange={(tags) => setConfig({...config, commands: tags})}
                inputProps={{maxLength: 15}}
            />
            <Text mt={4}>Szablon odpowiedzi</Text>
            <Input
                onFocus={(e) => e.currentTarget.select()}
                defaultValue={config.template || ''}
                onBlur={(e) => setConfig({ ...config, template: e.target.value })}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
            />
        </>
    );
}

export default ProgressConfigComponent;
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/css/App.css';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import AdminLayout from 'layouts/admin';
import {ChakraProvider} from '@chakra-ui/react';
import theme from 'theme/theme';
import {ThemeEditorProvider} from '@hypertheme-editor/chakra-ui';
import {AuthProvider} from "./contexts/AuthProvider";
import {Toaster} from "react-hot-toast";
import ExternalDashboard from "./layouts/external";
import {ClerkProvider, SignedIn} from "@clerk/clerk-react";
import { plPL } from '@clerk/localizations'

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider theme={theme}>
        <Toaster/>
        <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/" localization={plPL}>
            <AuthProvider>
                <SignedIn>
                        <BrowserRouter>
                            <Switch>
                                <Route path={`/admin`} component={AdminLayout}/>
                                <Route path={`/s`} component={ExternalDashboard}/>
                                <Redirect from='/' to='/admin/scenarios'/>
                            </Switch>
                        </BrowserRouter>
                </SignedIn>
            </AuthProvider>
        </ClerkProvider>
    </ChakraProvider>
);

import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Button,
    Code,
    Input,
    Select,
    Spacer,
    Text
} from "@chakra-ui/react";
import {useIsMount} from "../Config";
import MapField from "../../../../../components/fields/MapField";
import {copyJson, pasteJson} from "../../../../../contexts/DevContext";
import DevButton from "../../../../../components/button/DevButton";
import FileMapField from "../../../../../components/fields/FileMapField";

const VariableConfigComponent = ({initialConfig, onChange}) => {
    const isMount = useIsMount();
    const [vars, setVars] = useState(initialConfig.vars || []);

    useEffect(() => {
        if (isMount) return;
        onChange({vars: Object.values(vars).filter(v => !!v.name)});
    }, [vars]);

    const fields = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({key: i, label: `${i} os.`}));

    const handleMediaVariableUpdate = (i, items) => {
        setVars({
            ...vars,
            [parseInt(i)]: {
                ...vars[i],
                value: items
            },
        });
    }

    return (
        <Accordion allowToggle>
            {Object.values(vars).map((v, i) => (
                <AccordionItem border='none'>
                    <AccordionButton _hover={{background: 'none'}} style={{boxShadow: 'none'}} px={0}>
                        <AccordionIcon mr={2} />
                        Zmienna &nbsp;<Code>{v.name || '...'}</Code>
                        <Spacer/>
                        <DevButton size={'xs'} onClick={() => copyJson(v)}>Kopiuj</DevButton>
                        <Button variant={'outline'} size={'xs'} onClick={() => setVars(Object.values(vars).filter((_, j) => j !== i))}>Usuń</Button>
                    </AccordionButton>
                    <AccordionPanel pl={0} pb={4}>
                        <Text>Rodzaj</Text>
                        <Select defaultValue={v.type} onChange={e => setVars({ ...vars, [parseInt(i)]: { ...vars[i], type: e.target.value} })}>
                            {/*<option value="str">Wartość tekstowa</option>*/}
                            <option value="bool">Wartość logiczna</option>
                            <option value="pc">Statyczny tekst zależny od liczby graczy</option>
                            <option value="pcm">Statyczny załącznik zależny od liczby graczy</option>
                        </Select>

                        {v.type === 'pc' && <>
                            <Text mt={4}>Klucz</Text>
                            <Input defaultValue={v.name} onChange={e => setVars({ ...vars, [parseInt(i)]: { ...vars[i], name: e.target.value} })}></Input>
                            <Text mt={4}>Wartości</Text>
                            <MapField fields={fields} initialItems={v.value} onChange={items => setVars({ ...vars, [parseInt(i)]: { ...vars[i], value: items} })}/>
                        </>}
                        {v.type === 'pcm' && <>
                            <Text mt={4}>Klucz</Text>
                            <Input defaultValue={v.name} onChange={e => setVars({ ...vars, [parseInt(i)]: { ...vars[i], name: e.target.value} })}></Input>
                            <Text mt={4}>Wartości</Text>
                            <FileMapField fields={fields} initialItems={v.value} onChange={items => handleMediaVariableUpdate(i, items)}/>
                        </>}
                        {v.type === 'str' && <>
                            <Text mt={4}>Klucz</Text>
                            <Input defaultValue={v.name} onChange={e => setVars({ ...vars, [parseInt(i)]: { ...vars[i], name: e.target.value} })}></Input>
                        </>}
                        {v.type === 'bool' && <>
                            <Text mt={4}>Klucz</Text>
                            <Input defaultValue={v.name} onChange={e => setVars({ ...vars, [parseInt(i)]: { ...vars[i], name: e.target.value} })}></Input>
                        </>}
                        {/*<Input value={JSON.stringify(v)}></Input>*/}
                    </AccordionPanel>
                </AccordionItem>
            ))}
            <Button variant={'outline'} onClick={() => setVars([...Object.values(vars), {name: '', type: 'bool', value: {}}])} mt={4}>Dodaj zmienną</Button>
            <DevButton onClick={async () => setVars([...Object.values(vars), await pasteJson()])} mt={4} ml={2}>Wklej</DevButton>
        </Accordion>
    );
}

export default VariableConfigComponent;

import {Button} from "@chakra-ui/react";
import {isDevMode} from "../../contexts/DevContext";

const DevButton = (props) => {
  return (
    <Button
        variant={'outline'}
        color={'red.500'}
        {...props}
        display={isDevMode() ? 'inline-block' : 'none'}>
        {props.children}
    </Button>
  )
}

export default DevButton;

import React, {useEffect, useState} from 'react';
import {useIsMount} from "../Scenario";
import {AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList} from "@choc-ui/chakra-autocomplete";

const JumpStepComponent = ({id, v, onChange, lite, getStepsIds}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    const label = step => `Krok ${step ? ('#' + (parseInt(step.index, 10) + 1)) : ''}`;

    return (
        <>
            <AutoComplete onChange={val => setValue({ ...value, id: val })} defaultValue={label(getStepsIds().find(s => s.id === value.id))} openOnFocus>
                <AutoCompleteInput id={id} placeholder={'Wybierz krok'} />
                <AutoCompleteList>
                    {getStepsIds().filter(s => s.id !== id).map((step) => {
                        return (
                            <AutoCompleteItem key={`${id}-${step.id}`} label={label(step)} value={step.id}>
                                {label(step)}
                            </AutoCompleteItem>
                        );
                    })}
                </AutoCompleteList>
            </AutoComplete>
        </>
    );
}

export default JumpStepComponent;
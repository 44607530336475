import React from "react";

import {Icon} from "@chakra-ui/react";
import {MdTextsms,} from "react-icons/md";

import ScenarioList from "./views/admin/scenarios";
import ScenarioConfigEditor from "./views/admin/scenario-config";
import ScenarioEditorLite from "./views/admin/scenario/editor-lite";
import ScenarioEditor from "./views/admin/scenario/editor";
import ChatView from "./views/admin/chat";

const routes = [
  // {
  //   name: "Strona główna",
  //   layout: "/admin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  // },
  {
    name: "Konfiguracja",
    layout: "/admin",
    path: "/scenario/:id/config",
    icon: <Icon as={MdTextsms} width='20px' height='20px' color='inherit' />,
    component: ScenarioConfigEditor,
    show: false,
  },
  {
    name: "Scenariusz",
    layout: "/admin",
    path: "/scenario/:id",
    icon: <Icon as={MdTextsms} width='20px' height='20px' color='inherit' />,
    component: ScenarioEditor,
    show: false,
  },
  {
    name: "Scenariusze",
    layout: "/admin",
    path: "/scenarios",
    icon: <Icon as={MdTextsms} width='20px' height='20px' color='inherit' />,
    component: ScenarioList,
  },
  {
    name: "Widok edycji",
    layout: "/s",
    path: "/:id",
    icon: <Icon as={MdTextsms} width='20px' height='20px' color='inherit' />,
    component: ScenarioEditorLite,
    show: false,
  },
  {
    name: "Gra testowa",
    layout: "/admin",
    path: "/scenario/:scenarioId/test-game/:gameId",
    icon: <Icon as={MdTextsms} width='20px' height='20px' color='inherit' />,
    component: ChatView,
    show: false,
  }
];

export default routes;

import React, {useEffect, useState} from 'react';
import {TagsInput} from "react-tag-input-component";
import {Box, Button, Checkbox, Icon, IconButton, Input, Select, Text} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";
import ListInputField from "../../../../../components/fields/ListInputField";
import ConditionComponent from "../Condition";
import {MdAdd, MdClose} from "react-icons/md";

const indexToPath = i => String.fromCharCode(97 + i);

const ChoiceStepComponent = ({id, v, onChange, config, lite}) => {
    const {a, b, c, ...initialValue} = v;
    const isMount = useIsMount();
    const [value, setValue] = useState({
        ...initialValue,
        o: [
            ...[
                (v.a || []),
                (v.b || []),
                (v.c || []),
            ].filter(x => x.length).map(x => ({a: [...x]})),
            ...(v.o || []),
        ],
    });

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    const onChangeSkipping = (e) => {
        let skipping = e.target.value;
        if (!!skipping) {
            setValue({...value, s: skipping});
        } else {
            const { s, ...rest } = value;
            setValue(rest);
        }
    }

    return (
        <>
            <Text display={lite ? 'none' : 'block'}>
                Wiadomość (opcjonalna):
            </Text>
            <Input
                defaultValue={value.m || ''}
                onBlur={(e) => setValue({ ...value, m: e.target.value })}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                name={id + 'm'}
            />

            {(value.o || []).map((o, i) => (
            <Box>
                <IconButton variant={'ghost'} icon={<MdClose/>} float={'right'}
                            disabled={value.o.length <= 1}
                            size={'sm'}
                            onClick={() => {
                                let options = [...value.o];
                                options.splice(i, 1);
                                setValue({...value, o: options});
                            }}
                ></IconButton>
                <Text display={lite ? 'none' : 'block'} mt={4} key={i + 't'} pt={2}>
                    Ścieżka {indexToPath(i).toUpperCase()}:
                </Text>
                <Box mb={2}>
                    <TagsInput placeHolder="Opcja..."
                        name={id + i + 'a'}
                        value={[...new Set(o.a || [])]}
                        onChange={(tags) => {
                            let options = [...value.o];
                            options[i].a = tags;
                            setValue({...value, o: options});
                        }}
                    />
                </Box>
                <ConditionComponent
                    id={id + i + 'c'}
                    value={o.c || ''}
                    vars={(config?.variable?.vars || [])}
                    onChange={(c) => {
                        let options = [...value.o];
                        options[i].c = c;
                        setValue({...value, o: options});
                    }}
                />
            </Box>
            ))}

            <IconButton float={'right'}
                variant={'ghost'}
                icon={<MdAdd/>}
                onClick={() => {
                    let options = [...value.o];
                    options.push({a: []});
                    setValue({...value, o: options});
                }}>
            </IconButton>

            <Text display={lite ? 'none' : 'block'} mt={6}>Podpowiedzi:</Text>
            <ListInputField id={id + 'h'} items={value.h || []} onChange={(h) => setValue({...value, h})} locked={lite} />

            <Text display={lite ? 'none' : 'block'} mt={4}>Gdy podano złą odpowiedź:</Text>
            {(config?.wrongs?.default?.length || 0) > 0 ? (
                    <Select id={id + 'eid'} value={value.eid || ''} display={lite ? 'none' : 'block'}
                            onChange={(e) => setValue({...value, eid: e.target.value || undefined})} mt={2}>
                        <option value="">Domyślne</option>
                        {config?.wrongs?.extra?.map(e => (
                            <option key={e.id} value={e.id}>Pakiet {e.name}</option>
                        ))}
                    </Select>
                ) :
                ''}

            <Text display={lite ? 'none' : 'block'} mt={4}>Gdy gracz próbuje pominąć wybór:</Text>
            <Input
                defaultValue={value.s}
                onChange={onChangeSkipping}
                id={id + 's'}
            />
            <Checkbox isChecked={true} disabled={true} display={lite ? 'none' : 'block'}>
                Pomijanie jest zablokowane
            </Checkbox>
        </>
    );
}

export default ChoiceStepComponent;
import React, {useEffect, useState} from 'react';
import {Input} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";
import {isDevMode} from "../../../../../contexts/DevContext";
import FileField from "../../../../../components/fields/FileField";

const MediaStepComponent = ({ id, v, onChange }) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(typeof v === 'string' ? {t: v, _f: '', _ts: ''} : v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    return (
        <>
            <FileField id={id} initialValue={{ token: v.t, filename: v._f, type: v._t, timestamp: v._ts }} onChange={v => setValue({t: v.token, _f: v.filename, _t: v.type, _ts: v.timestamp})} />
            <Input
                value={value.t}
                onFocus={(e) => e.currentTarget.select()}
                onBlur={(e) => setValue({ ...value, t: e.target.value })}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        e.target.blur();
                    }
                }}
                id={id}
                border={'none'}
                visibility={!isDevMode() ? 'hidden' : 'visible'}
                maxWidth={'300px'}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                mr={2}
                readOnly={true}
            />
        </>
    );
};

export default MediaStepComponent;
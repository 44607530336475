import React, {useEffect, useState} from 'react';
import {Input, NumberInput, Textarea} from "@chakra-ui/react";
import {useIsMount} from "../Scenario";

const DelayStepComponent = ({id, v, onChange}) => {
    const isMount = useIsMount();
    const [value, setValue] = useState(v);

    useEffect(() => {
        if (isMount) return;
        onChange(value);
    }, [value]);

    return (
        <>
            Opóźnienie:
            <Input
                defaultValue={value}
                type={'number'}
                step={10}
                min={0}
                max={300}
                onBlur={(e) => setValue(e.target.value)}
                onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() }}}
                id={id}
            />
        </>
    );
}

export default DelayStepComponent;
import {
    Button,
    Checkbox,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Textarea
} from "@chakra-ui/react";
import {IoMdCog} from "react-icons/io";
import React, {useEffect, useState} from "react";
import {aiApi} from "../../../../api";
import {useAuth} from "../../../../contexts/AuthProvider";

export default function ScenarioProcessor({steps: initialSteps, open, onClose, onChange}) {
    const [steps, setSteps] = useState(initialSteps || []);
    const [prompt, setPrompt] = useState('');
    const [dryRun, setDryRun] = useState(false);
    const [progress, setProgress] = useState(0);
    const {awaitAuthentication} = useAuth();

    const onProcess = async () => {
        setProgress(1);
        let newSteps = [...steps];

        const chunkSize = 25;
        for (let i = 0; i < steps.length; i += chunkSize) {
            console.log(i);

            const chunkedSteps = steps.slice(i, i + chunkSize);

            let processedSteps = [];

            while (true) {
                try {
                    processedSteps = await aiApi({awaitAuthentication}).process(chunkedSteps, prompt);
                    break;
                } catch (e) {
                    if (e.response?.status === 529) {
                        console.log('Retrying...');

                        await new Promise(r => setTimeout(r, 3000));
                    } else {
                        throw e;
                    }
                }
            }

            await new Promise(r => setTimeout(r, 500));

            newSteps = newSteps.map(s => processedSteps.find(p => p.id === s.id) || s);
            setProgress((i + chunkSize) / steps.length * 100);

            if (dryRun) {
                break;
            }
        }

        setSteps(newSteps);
        setProgress(100);
    }

    useEffect(() => {
        if (progress === 100) {
            setProgress(0);
            onChange(steps);
            onClose();
        }
    }, [progress]);


    const handleClose = () => {
        setProgress(0);
        onClose();
    }

    return (
        <>
            <Modal isOpen={open} onClose={handleClose} size={'5xl'}>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        Przetwarzanie
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <Textarea placeholder="Polecenie dla AI" onChange={(e) => setPrompt(e.target.value)} />
                        <Checkbox onChange={(e) => setDryRun(e.target.checked)}>Tylko testowo</Checkbox>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onProcess} isDisabled={!prompt} isLoading={progress > 0} leftIcon={<IoMdCog/>} variant="outline">Rozpocznij</Button>
                        <Progress width={'100%'} hasStripe isAnimated={true} value={progress} ml={2} />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

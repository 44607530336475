/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Spacer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
// Assets
// Custom components
import React from "react";
import Card from "../../../components/card/Card";
import {Link as RouterLink} from "react-router-dom";
import {MdCreate} from "react-icons/md";
import {scenarioApi} from "../../../api";
import {useAuth} from "../../../contexts/AuthProvider";
import {isDevMode} from "../../../contexts/DevContext";
import toast from "react-hot-toast";

export default function ScenarioList() {
    const [scenarios, setScenarios] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const newScenarioName = React.useRef();
    const newScenarioId = React.useRef();
    const { isOpen: isNewOpen, onOpen: onNewOpen, onClose: onNewClose } = useDisclosure()
    const {awaitAuthentication, hasRole} = useAuth();

    const fetchScenarios = async () => {
        setLoading(true);
        await scenarioApi({awaitAuthentication}).listAll()
            .then(scenarios => {
                setScenarios(scenarios);
            })
            .catch(() => {
                setScenarios([])
                toast.error('Nie udało się pobrać scenariuszy.');
            });
        setLoading(false);
    };

    React.useEffect(() => {
        fetchScenarios();
    }, []);

    const onCreateNew = () => {
        const name = newScenarioName.current.value;
        const id = newScenarioId.current.value;

        const fetchData = async () => {
            await scenarioApi({awaitAuthentication})
                .create({name, id})
                .then(() => {
                    setLoading(true);
                    fetchScenarios()
                    toast.success('Utworzono nowy scenariusz.');
                    onNewClose();
                })
                .catch(() => {
                    toast.error('Nie udało się utworzyć nowego scenariusza.');
                });
        };

        fetchData();
    }

    // Chakra Color Mode
    return (
        <Box>
            <Modal isOpen={isNewOpen} onClose={onNewClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Nowy scenariusz</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Box css={{display: isDevMode() ? 'block' : 'none'}}>
                            ID <Input ref={newScenarioId} />
                        </Box>
                        Nazwa <Input ref={newScenarioName} />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="brand" onClick={onCreateNew}>Utwórz</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Card px={0}>
                <Flex>
                    <Spacer/>
                    <Button isDisabled={!hasRole('admin') && !hasRole('org:manager')} leftIcon={<MdCreate/>} onClick={onNewOpen} mr={6}>Utwórz nowy</Button>
                </Flex>
                <Skeleton isLoaded={!loading} m={loading ? 5 : 0} opacity={loading ? 0.2 : 1} variant="shine">
                    <Table variant="striped">
                        <Thead>
                            <Tr>
                                <Th>Scenariusz</Th>
                                <Th textAlign={'right'}>Ostatnia modyfikacja</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {scenarios.map((scenario) => (
                                <Tr key={scenario.id}>
                                    <Td>
                                        {scenario.name}
                                    </Td>
                                    <Td textAlign={'right'}>
                                        {new Date(scenario.updatedAt).toISOString().substring(0, 10)}
                                        <br/>
                                        <small>{new Date(scenario.updatedAt).toLocaleTimeString()}</small>
                                    </Td>
                                    <Td textAlign={'right'}>
                                        <Button variant={'outline'} mr={2}>
                                            <Link as={RouterLink}
                                                  to={'/admin/scenario/' + scenario.id}>Edycja</Link>
                                        </Button>
                                        <Button variant={'outline'}>
                                            <Link as={RouterLink}
                                                  to={'/admin/scenario/' + scenario.id + '/config'}>Konfiguracja</Link>
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                            {scenarios.length === 0 && (
                                <Tr>
                                    <Td colSpan={3} textAlign={'center'}>Brak scenariuszy</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </Skeleton>
            </Card>
        </Box>
    );
}
